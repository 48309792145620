.app-header__logo {
  .logo-src {
    width: 140px;
    height: auto;
  }
}

a:hover {
  text-decoration: none;
}

.table-responsive-mh{
  min-height: 340px;
}

.app-main__inner {
  min-height: 500px;
}

.page-item.active .page-link, .pagination .active.page-number .page-link {
  border-color: #033C73;
}

.pagination {
  justify-content: right;
}

.pagination li a, .pagination li a:hover {
  color: #033C73;
}

._loading_overlay_wrapper {
  overflow-x: hidden;
}

.rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
  background-color: #EEE;
  border-color: #EEE;
  color: black;
}

.units-table th:nth-last-child(2) {
  width: 1px;
}

.units-table th:nth-last-child(3) {
  width: 120px;
}

.units-table th:last-child {
  width: 70px;
}

.bg-instrumentation-header-success {
  background-color: #cbe8ba !important;
}

.bg-instrumentation-body-success {
  background-color: #dfedd6 !important;
}

.widget-content #fees{
  width: 80px;
}
.btn-show-hide-calculations{
  position: absolute!important;
  right: -10px;
  top: -10px;
  z-index: 1;
}

.col-discount-sh{
  text-align: left!important;
  .form-check-input{
    margin: 0 5px 0 0;
    position: static;
  }
}

@media (max-width: 992px) {
  .app-footer .app-footer__inner .app-footer-right {
    display: block;
  }
}

@media (max-width: 767px) {
  .card-header.header-2c-mobile{
    display: flex;
    text-align: left;
    .btn-actions-pane-right{
      padding: 0;
    }
  }
  .modal-footer-edit{
    span {
     display: none;
    }
    .btn-icon-wrapper{
      margin: 0;
    }
  }
  .react-bootstrap-table-pagination > div:first-child {
    margin-bottom: 10px;
  }
  .pagination {
    justify-content: center;
  }
  .unit-logo-wrapper{
    flex-wrap: wrap;
  }
  .unit-logo{
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .widget-content-left{
    width: 65%;
  }
  .widget-content-right{
    width: 35%;
    text-align: right;
  }
  body .card-header .actions-icon-btn{
    margin-top: 10px;
    text-align: left;
  }
  .card-unit{
    .card-header{
      display: flex;
    }
    .btn-delete-unit .header-icon{
      display: block;
    }
    .unit-logo-wrapper .unit-logo {
      width: 100%;
    }
    .widget-content-left,
    .widget-content-right{
      width: 50%;
    }
  }
  .row-widget .widget-content-wrapper{
    display: block;
    .widget-content-left{
      width: 100%;
    }
    .widget-content-right{
      margin-top: 10px;
      width: 100%;
    }
  }
  .card-header.card-header-check{
    display: flex;
    text-align: left;
    padding: 0.75rem;
    .form-check{
      margin-left: 20px!important;
    }
    .btn-actions-pane-right{
      display: none;
    }
  }
}

.filters .form-control{
  font-size: .85rem;
  &::placeholder {
    color: #999;
  }
}

