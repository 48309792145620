@charset "UTF-8";

@import "variables";

@keyframes #{$rt-namespace}__trackProgress {
  0%{
    transform: scaleX(1);
  }
  100%{
    transform: scaleX(0);
  }
}

.#{$rt-namespace}__progress-bar {
  animation: #{$rt-namespace}__trackProgress linear 1 forwards;
}
